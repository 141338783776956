import React from "react"
import Header from "./home/_header"
import HeroHome from "./home/_hero-home"
import ApplicationProcess from "./home/_application-process"
import WhoShouldApply from "./home/_who-should-apply"
import HowToApply from "./home/_how-to-apply"
import SelectionCommittee from "./home/_selection-committee"
import ApprovedCourses from "./home/_approved-courses"
// import Testimonials from "./home/Testimonials"
// import Newsletter from "./home/Newsletter"
import Footer from "./home/_footer"

function Home({ pathname }) {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header pathname={pathname} />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page sections */}
        <HeroHome />
        <WhoShouldApply />
        <ApprovedCourses />
        <ApplicationProcess />
        <HowToApply />
        <SelectionCommittee />
        {/* <Testimonials /> */}
        {/* <Newsletter /> */}
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  )
}

export default Home
