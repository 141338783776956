import React, { useState } from "react"
// import Modal from "../../utils/Modal"

// import HeroImage from "../../images/hero-image.png"

function HeroHome() {
  const [videoModalOpen, setVideoModalOpen] = useState(false)

  return (
    <section className="relative">
      {/* Illustration behind hero content */}
      <div
        className="absolute left-1/2 transform -translate-x-1/2 bottom-0 pointer-events-none"
        aria-hidden="true"
      >
        <svg
          width="1360"
          height="578"
          viewBox="0 0 1360 578"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient
              x1="50%"
              y1="0%"
              x2="50%"
              y2="100%"
              id="illustration-01"
            >
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1232" cy="128" r="128" />
            <circle cx="155" cy="443" r="64" />
          </g>
        </svg>
      </div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1
              className="text-4xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-8"
              //   data-aos="zoom-y-out"
            >
              University Tuition
              <br />
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-400">
                Scholarships
              </span>
              <br />
              <span className="text-3xl md:text-5xl font-extrabold leading-tighter tracking-tighter">
                (for UEC Graduates)
              </span>
            </h1>
            <div className="max-w-3xl mx-auto mt">
              <h2 className="h2 mb-4">What is this?</h2>
              <p className="text-xl text-gray-600 mb-8">
                A donor has set up a Tuition Scholarship Fund at{" "}
                <a
                  className="text-blue-600 hover:underline"
                  href="https://www.tarc.edu.my/"
                  target="_blank"
                >
                  Tunku Abdul Rahman University of Management and Technology
                  (“TAR UMT”)
                </a>{" "}
                for Sarawakians with Unified Examination Certificate (“UEC”) to
                pursue Bachelor Degree courses at TAR UMT’s Kuala Lumpur Main
                Campus.
              </p>
              <p className="text-xl text-gray-600 mb-8">
                The Tuition Scholarship Fund has been set up by the donor who
                was a beneficiary of the generosity of others. He was granted a
                scholarship which enabled him to pursue his tertiary education,
                develop a career and provide his children with good education.
              </p>
              <p className="text-xl text-gray-600 mb-8">
                Out of gratitude for the help he received, the donor has set up
                this Tuition Scholarship Fund at TAR UMT to help UEC graduates
                who need financial assistance to pursue a degree course at TAR
                UMT.
              </p>
              <p className="text-xl text-gray-600 mb-8">
                For 2024, one or two Tuition Scholarships for Bachelor Degree
                courses will be awarded to successful applicants to study at{" "}
                <u className="text-black">TAR UMT’s Kuala Lumpur Main Campus</u>
                , for the June enrolment.
              </p>
              <div className="max-w-4xl p-4 text-gray-800 bg-white rounded-lg shadow">
                <div className="mb-2">
                  <div className="h-3 text-3xl text-left text-blue-400 ml-2">
                    “
                  </div>
                  <p className="px-5 text-lg text-center text-blue-400">
                    Education breeds confidence. Education breeds hope. Hope
                    breeds peace.
                  </p>
                  <div className="h-3 text-3xl text-right text-blue-400 mr-2">
                    ”
                  </div>
                  <p className="text-sm text-center text-gray-600 mt-4">
                    - Confucius
                  </p>
                </div>
              </div>
              {/* <h2 className="h2 mb-4">Why is this here?</h2>
              <p className="text-xl text-gray-600 mb-8">
                In gratitude for the help he received from others, the donor now
                wishes to do his little bit to help two deserving students who
                need financial assistance to pursue a degree course at{" "}
                <a
                  className="text-blue-600 hover:underline"
                  href="https://www.tarc.edu.my/"
                  target="_blank"
                >
                  Tunku Abdul Rahman University of Management and Technology
                </a>
                , Kuala Lumpur.
              </p> */}
              <div
                className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center"
                // data-aos="zoom-y-out"
                // data-aos-delay="300"
              >
                {/* <div>
                  <a
                    className="btn text-white bg-blue-600 hover:bg-blue-700 w-full mb-4 sm:w-auto sm:mb-0"
                    href="#0"
                  >
                    Start free trial
                  </a>
                </div> */}
                {/* <div>
                  <a
                    className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                    href="/apply"
                  >
                    Apply now
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroHome
