import React from "react"
import Transition from "../../utils/Transition"

// import FeaturesBg from "../images/features-bg.png"
// import FeaturesElement from "../images/features-element.png"

function SelectionCommittee() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 bg-gray-100 pointer-events-none mb-16"
        aria-hidden="true"
      ></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-xl mx-auto text-center pb-12 md:pb-16 lg:max-w-full">
            <h2 className="h2 mb-4">The Selection Committee</h2>
            <h3 className="h5 mb-4">
              The Selection Committee will shortlist and select the awardees.
              <br />
              The Selection Committee comprises of:
            </h3>
            <div className="flex justify-center">
              <ol className="h5 mb-4 text-left list-decimal ml-8">
                <li>
                  <b>Mr.Sun Nan Ping</b> - Chairman
                </li>
                <li>
                  <b>Dr. Chin Vei Ching</b>
                </li>
                <li>
                  <b>Mr. Richard Kiew Jiat Fong</b>
                </li>
              </ol>
            </div>
            <div className="flex flex-col justify-space-between items-center w-full mt-12">
              <div className="flex flex-col w-full lg:flex-row">
                <div className="mr-6 w-32 h-32 sm:w-48 sm:h-48 sm:mr-8">
                  <img
                    src="images/sun.jpg"
                    alt="Mr Sun Nan Ping"
                    className="shadow-lg rounded-full h-auto align-middle border-none"
                  />
                </div>
                <div className="w-full flex flex-col items-start">
                  <h3 className="font-bold text-lg mt-4 lg:mt-0">
                    Mr. Sun Nan Ping
                  </h3>
                  <small className="text-base text-left text-gray-600">
                    Mr Sun Nan Ping was born and grew up in Sarawak.
                    <br />
                    He is a proud Sarawakian. Mr Sun completed his primary
                    education at St. Thomas Primary School and his secondary
                    education at St. Thomas Secondary School, Kuching. After his
                    A-levels he matriculated at the University of Malaya. There
                    he graduated with a Bachelor Degree in chemical engineering.
                    <br />
                    Mr Sun’s entire career was devoted to manufacturing. He
                    worked and built his career in both West and East Malaysia.
                    Prior to his retirement he was the Chief Executive Officer
                    of Sarawak’s largest manufacturer of polyethylene pipes,
                    Polyflow Pipes Sdn Bhd which he served for more than 23
                    years.
                    <br />
                    Mr Sun is a strong believer in education both as a means to
                    escape the poverty trap and an end in itself.
                  </small>
                </div>
              </div>
              <div className="flex flex-col w-full mt-10 lg:flex-row">
                <div className="mr-6 w-32 h-32 sm:w-48 sm:h-48 sm:mr-8">
                  <img
                    src="images/chin.jpg"
                    alt="Dr. Chin Vei Ching"
                    className="shadow-lg rounded-full h-auto align-middle border-none"
                  />
                </div>
                <div className="w-full flex flex-col items-start">
                  <h3 className="font-bold text-lg mt-4 lg:mt-0">
                    Dr. Chin Vei Ching
                  </h3>
                  <small className="text-base text-left text-gray-600">
                    Dr Chin Vei Ching was born in an grew up in Sarawak.
                    <br />
                    Dr Chin had his early education in Chinese primary school
                    and his secondary education at Kuching High School with
                    Chinese as a subject up to Form 5. He completed his form six
                    classes at St. Thomas Secondary School, Kuching.
                    <br />
                    After A-levels, he studied at Malaysia Agriculture
                    University where he graduated as a Doctor of Veterinary
                    Medicine in 1978.
                    <br />
                    Dr Chin’s entire career was with the Sarawak Civil Service,
                    starting as a Veterinary Officer in the Agriculture
                    Department. Thereafter he served as Divisional Veterinary
                    Officer in Kuching, Sibu and Miri before he was posted back
                    to the Veterinary Headquarter in Kuching in 1995. He retired
                    as the Head of Sarawak’s State Veterinary Services in 2010.
                    <br />
                    After his retirement he was appointed as Project Director
                    and completed the 2,000 acres Pig Farming Project until
                    2014.
                  </small>
                </div>
              </div>
              <div className="flex flex-col w-full mt-10 lg:flex-row">
                <div className="mr-6 w-32 h-32 sm:w-48 sm:h-48 sm:mr-8">
                  <img
                    src="images/richard.png"
                    alt="Mr Richard Kiew"
                    className="shadow-lg rounded-full h-auto align-middle border-none"
                  />
                </div>
                <div className="w-full flex flex-col items-start">
                  <h3 className="font-bold text-lg mt-4 lg:mt-0">
                    Mr. Richard Kiew Jiat Fong
                  </h3>
                  <small className="text-base text-left text-gray-600">
                    Mr Richard Kiew is a Chartered Accountant.
                    <br />
                    He is a member of Malaysian Institute of Accountants and a
                    Fellow of the Institute of Chartered Accountants in England
                    and Wales, The Association of Chartered Certified
                    Accountants, Singapore Chartered Accountants and Chartered
                    Tax Institute Of Malaysia.
                    <br />
                    Mr Kiew has 43 years of professional experience in the field
                    of accountancy, taxation, finance and investments.
                    <br />
                    He is an approved company auditor, tax agent and company
                    secretary being licensed by the Treasury, Ministry of
                    Finance and Companies Commission, Malaysia respectively.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SelectionCommittee
