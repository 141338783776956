import { Link } from "gatsby"
import React from "react"
import { ExclamationIcon } from "@heroicons/react/solid"
import { StageOne } from "./_application-process"

function HowToApply() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      {/* <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none"
        aria-hidden="true"
      ></div> */}
      {/* <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pb-12 md:pb-20">
          {/* Section header */}
          <div className="max-w-full sm:max-w-3xl mx-auto text-center pb-12 sm:pb-20">
            <h2 className="h2 mb-4">How to apply?</h2>
            <div className="flex mb-4 items-center justify-center">
              <ExclamationIcon className="h-14 w-14 sm:h-6 sm:w-6 text-yellow-400 mr-4 sm:mr-2" />
              <p className="text-xl text-left">
                Please read the instructions and requirements carefully before
                applying.
              </p>
            </div>
            <p className="text-xl text-gray-600">
              Please only apply for the Tuition Scholarships if you meet all the
              requirements as stated above. Candidates shortlisted during Stage
              (1) Pre-Selection will be invited by email to proceed to Stage (2)
              Application. Detailed instructions for Stage (2) Application will
              be provided then.
            </p>
          </div>

          {/* Items */}
          <div className="max-w-full sm:max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">
            {/* 1st item */}
            <div className="relative flex flex-col items-center h-full p-6 bg-white rounded shadow-xl">
              <svg
                width="48"
                height="48"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M13.5 12.4991L16.5 10.5V22"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2">
                Step One
              </h4>
              <p className="text-gray-600 text-center">
                Please make sure you meet all the requirements of the Tuition
                Scholarships. If you do, please download the application form
                from this{" "}
                <a
                  className="text-blue-600 hover:underline"
                  href="/forms/form-a.xlsx"
                  target="_blank"
                >
                  link
                </a>{" "}
                and complete it. If you would rather print the form, please find
                the PDF version{" "}
                <a
                  className="text-blue-600 hover:underline"
                  href="/forms/form-a.pdf"
                  target="_blank"
                >
                  here
                </a>
                .
              </p>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center h-full p-6 bg-white rounded shadow-xl">
              <svg
                width="48"
                height="48"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M13.2358 11.8324C13.3986 11.4469 13.6404 11.0999 13.9454 10.8134C14.2504 10.5269 14.6119 10.3074 15.0067 10.169C15.4016 10.0306 15.8211 9.97623 16.2382 10.0095C16.6553 10.0428 17.0608 10.1629 17.4287 10.3622C17.7966 10.5615 18.1188 10.8356 18.3745 11.1668C18.6302 11.498 18.8138 11.879 18.9135 12.2854C19.0133 12.6918 19.0269 13.1145 18.9535 13.5264C18.8802 13.9384 18.7215 14.3304 18.4876 14.6774V14.6774L13 22.0001V21.9994H19"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2">
                Step Two
              </h4>
              <p className="text-gray-600 text-center">
                You can either submit online or by post. To submit online, head
                over to the{" "}
                <Link className="text-blue-600 hover:underline" to="/apply">
                  apply page
                </Link>{" "}
                to complete the submission. For submission via post, you can
                find the postal address in the application process section
                above.
              </p>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center h-full p-6 bg-white rounded shadow-xl">
              <svg
                width="48"
                height="48"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M12.9996 10.499H18.9996L15.5004 15.5C16.076 15.5 16.6427 15.642 17.1503 15.9133C17.6579 16.1846 18.0908 16.5769 18.4106 17.0555C18.7303 17.5341 18.9272 18.0841 18.9836 18.6569C19.04 19.2298 18.9543 19.8076 18.734 20.3394C18.5137 20.8712 18.1657 21.3404 17.7208 21.7055C17.2759 22.0707 16.7478 22.3205 16.1832 22.4328C15.6187 22.545 15.0352 22.5164 14.4844 22.3493C13.9336 22.1822 13.4325 21.8819 13.0256 21.4749"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2">
                Step Three
              </h4>
              <p className="text-gray-600 text-center">
                All submissions must be sent in by the{" "}
                <b className="text-black">28th February 2024</b>. If you are
                pre-selected, you will be contacted by email to proceed to Stage
                (2) Application.
              </p>
            </div>

            {/* 4th item */}
            {/* <div className="relative flex flex-col items-center h-full p-6 bg-white rounded shadow-xl">
              <svg
                width="48"
                height="48"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M15.4998 9.5L12.5 18H18.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.5 14V22"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2">
                Step Four
              </h4>
              <p className="text-gray-600 text-center">
                A number of the most qualified candidates will be short listed
                and will be invited to submit the full application form -
                Tuition Scholarship Application together with supporting
                documents/references.
              </p>
            </div> */}

            {/* 5th item */}
            {/* <div className="relative flex flex-col items-center h-full p-6 bg-white rounded shadow-xl">
              <svg
                width="48"
                height="48"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M19 10.5H13.9793L13 16.5253C13.4895 16.0358 14.1131 15.7025 14.792 15.5674C15.4709 15.4324 16.1747 15.5017 16.8142 15.7666C17.4537 16.0315 18.0003 16.4801 18.3849 17.0556C18.7695 17.6312 18.9747 18.3079 18.9747 19.0001C18.9747 19.6923 18.7695 20.369 18.3849 20.9445C18.0003 21.5201 17.4537 21.9687 16.8142 22.2336C16.1747 22.4985 15.4709 22.5678 14.792 22.4328C14.1131 22.2977 13.4895 21.9644 13 21.4749"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2">
                Step Five
              </h4>
              <p className="text-gray-600 text-center">
                Out of the candidates who are short listed after Step Four, a
                handful of them will be selected for the interview with The
                Selection Committee.
              </p>
            </div> */}

            {/* 6th item */}
            {/* <div className="relative flex flex-col items-center h-full p-6 bg-white rounded shadow-xl">
              <svg
                width="48"
                height="48"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-miterlimit="10"
                />
                <path
                  d="M12.9689 16.2487L17 9.5"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 21.5C17.933 21.5 19.5 19.933 19.5 18C19.5 16.067 17.933 14.5 16 14.5C14.067 14.5 12.5 16.067 12.5 18C12.5 19.933 14.067 21.5 16 21.5Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2">
                Step 6
              </h4>
              <p className="text-gray-600 text-center">
                Two candidates will be selected as the recipients for the Degree
                Course Tuition Scholarship. The scholarship is renewable every
                semester subject to academic performance of the recipient.
              </p>
            </div> */}
          </div>
          <div className="flex justify-center mt-16">
            <a
              className="btn text-white bg-gray-900 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
              href="/apply"
            >
              Apply now
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowToApply
