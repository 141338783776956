import { Link } from "gatsby"
import React from "react"

export const StageOne = () => (
  <>
    <div className="flex items-center">
      <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2 flex items-center">
        Stage
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-1 mr-2"
        >
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <path
            d="M13.5 12.4991L16.5 10.5V22"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        (Pre-selection)
      </h4>
    </div>
    <p className="text-xl text-left text-gray-600 mt-2">
      All applicants are required to complete a{" "}
      <Link className="text-blue-600 hover:underline" to="/forms/form-a.xlsx">
        Pre-Selection Form (Form A)
      </Link>
      . Applicants are strongly encouraged to complete and submit Form A online
      through{" "}
      <Link className="text-blue-600 hover:underline" to="/apply">
        here
      </Link>
      . However, if for any reasons they are unable to do so, they can print and
      complete the form and mail it to :
    </p>
    <p className="text-xl text-gray-600 my-8">
      Richard Kiew &amp; Company <br />
      P.O. Box 2536
      <br />
      93750 Kuching
      <br />
      Sarawak, Malaysia
    </p>
    <p className="text-xl font-bold text-left">Deadline: 28 February 2024</p>
  </>
)

const StageTwo = () => (
  <>
    <div className="flex items-center mt-4">
      <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2 flex items-center">
        Stage
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-1 mr-2"
        >
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <path
            d="M13.2358 11.8324C13.3986 11.4469 13.6404 11.0999 13.9454 10.8134C14.2504 10.5269 14.6119 10.3074 15.0067 10.169C15.4016 10.0306 15.8211 9.97623 16.2382 10.0095C16.6553 10.0428 17.0608 10.1629 17.4287 10.3622C17.7966 10.5615 18.1188 10.8356 18.3745 11.1668C18.6302 11.498 18.8138 11.879 18.9135 12.2854C19.0133 12.6918 19.0269 13.1145 18.9535 13.5264C18.8802 13.9384 18.7215 14.3304 18.4876 14.6774V14.6774L13 22.0001V21.9994H19"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        (Application)
      </h4>
    </div>
    <p className="text-xl text-left text-gray-600 mt-2">
      A number of the most qualified candidates will be short listed and invited
      to submit the full application form - Tuition Scholarship Application
      (Form B) together with supporting documents / references.
      {/* <br />
      <br />
      Please do not submit Form B unless and until you are invited to do so.
      Form B will be posted on our website in due course. */}
    </p>
  </>
)

const StageThree = () => (
  <>
    <div className="flex items-center mt-4">
      <h4 className="text-xl font-bold leading-snug tracking-tight mb-1 mt-2 flex items-center">
        Stage
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ml-1 mr-2"
        >
          <path
            d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
            stroke="black"
            stroke-width="2"
            stroke-miterlimit="10"
          />
          <path
            d="M12.9996 10.499H18.9996L15.5004 15.5C16.076 15.5 16.6427 15.642 17.1503 15.9133C17.6579 16.1846 18.0908 16.5769 18.4106 17.0555C18.7303 17.5341 18.9272 18.0841 18.9836 18.6569C19.04 19.2298 18.9543 19.8076 18.734 20.3394C18.5137 20.8712 18.1657 21.3404 17.7208 21.7055C17.2759 22.0707 16.7478 22.3205 16.1832 22.4328C15.6187 22.545 15.0352 22.5164 14.4844 22.3493C13.9336 22.1822 13.4325 21.8819 13.0256 21.4749"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        (Interview)
      </h4>
    </div>
    <p className="text-xl text-left text-gray-600 mt-2">
      Interview with The Selection Committee in Kuching.
    </p>
  </>
)

function ApplicationProcess() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 bg-gray-100 pointer-events-none mb-16"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="max-w-full sm:max-w-3xl mx-auto text-center pb-12 sm:pb-20">
            <h2 className="h2 mb-4">Application process</h2>
            <StageOne />
            <StageTwo />
            <StageThree />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApplicationProcess
