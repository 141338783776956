import React from "react"
// import Transition from "../../utils/Transition"

// import FeaturesBg from "../images/features-bg.png"
// import FeaturesElement from "../images/features-element.png"

function ApprovedCourses() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      {/* <div
        className="absolute inset-0 bg-gray-100 pointer-events-none mb-16"
        aria-hidden="true"
      ></div> */}
      {/* <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div> */}

      <div className="max-w-3xl mx-auto pb-12 px-4 md:pb-16">
        <h2 className="h2 mb-4 text-center">Approved courses</h2>
        <ul className="list-disc space-y-4">
          <li className="ml-4">
            <p className="text-xl text-gray-600 text-left">
              Any Bachelor Degree Course offered by TAR UMT, Kuala Lumpur Main
              Campus. Applicants must ensure their intended course of study is
              available for the June 2024 enrolment.
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-gray-600 text-left">
              Successful candidates will have the sole responsibility to apply
              for their admission to TAR UMT and the award of the Tuition
              Scholarship is contingent upon their successful application. If
              they are not successful in their application for admission, the
              scholarship offer will lapse.
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-gray-600 text-left">
              The Tuition Scholarships do not carry any bond. It is the hope of
              the donor that the successful candidates will in future try to
              help others in need if they are in the position to do so.
            </p>
          </li>
        </ul>
        {/* <ul className="list-disc space-y-4">
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/fafb/programmes/bachelor-degree/bachelor-of-accounting-honours/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Accountacy
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/foas/programmes/bachelor-degree/bachelor-of-science-hons-in-applied-physics-instrumentation/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Applied Physics
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/fobe/programme/bachelor-degree/bachelor-of-science-in-architecture-honours/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Architecture
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/fafb/programmes/bachelor-degree/bachelor-of-banking-and-finance-honours/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Banking & Finance
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/fssh/programmes/bachelor-degree/bachelor-of-early-childhood-education-honours/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Early Childhood Education
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/fafb/programmes/bachelor-degree/bachelor-of-economics-honours/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Economics
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/foet/programmes/bachelor-degree/bachelor-of-engineering-honours-electrical-and-electronics/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Electrical & Electronic Engineering
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/foet/programmes/bachelor-degree/bachelor-of-engineering-honours-mechanical/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Mechanical Engineering
              </a>
            </p>
          </li>
          <li className="ml-4">
            <p className="text-xl text-left">
              <a
                href="https://www.tarc.edu.my/fobe/programme/bachelor-degree/bachelor-of-quantity-surveying-honours/"
                target="_blank"
                className="text-blue-600 hover:underline text-left"
              >
                Quantity Surveying
              </a>
            </p>
          </li>
        </ul> */}
      </div>
    </section>
  )
}

export default ApprovedCourses
