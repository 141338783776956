import React, { useState, useRef, useEffect } from "react"
import Transition from "../../utils/Transition"

// import FeaturesBg from "../images/features-bg.png"
// import FeaturesElement from "../images/features-element.png"

function WhoShouldApply() {
  return (
    <section className="relative">
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div
        className="absolute inset-0 bg-gray-100 pointer-events-none mb-16"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h2 className="h2 mb-4">Who should apply?</h2>
            <ul className="list-disc space-y-4">
              <li className="ml-4">
                <p className="text-xl text-gray-600 text-left">
                  Students who obtained their Unified Examination Certificate in{" "}
                  <u>2021, 2022 or 2023</u> and need financial assistance.
                </p>
              </li>
              <li className="ml-4">
                <p className="text-xl text-gray-600 text-left">
                  Applicant's family month household income{" "}
                  <u>must not exceed MYR 7,000</u>
                </p>
              </li>
              <li className="ml-4">
                <p className="text-xl text-gray-600 text-left">
                  Above average student
                </p>
              </li>
              <li className="ml-4">
                <p className="text-xl text-gray-600 text-left">
                  Must have a sufficient command of English as almost all
                  courses are conducted in English
                </p>
              </li>
              <li className="ml-4">
                <p className="text-xl text-gray-600 text-left">
                  Applicants must be <u>born in Sarawak</u> and have completed
                  all their primary and secondary schooling in Sarawak. They
                  must be <u>no older than 23 years</u> at the time of
                  application.
                </p>
              </li>
            </ul>
            <p className="text-xl font-bold text-left mt-4">
              Only those who meet <u>ALL</u> the above criteria are invited to
              apply.
            </p>
            <p className="text-xl text-gray-600 text-left mt-4">
              In addition, please refer to the{" "}
              <a
                className="text-blue-600 hover:underline"
                href="/forms/terms-and-conditions.pdf"
                target="_blank"
              >
                terms and condition
              </a>
              .
            </p>
            <div>
              <a
                className="btn text-white bg-gray-900 mt-8 hover:bg-gray-800 w-full sm:w-auto sm:ml-4"
                href="/apply"
              >
                Apply now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoShouldApply
